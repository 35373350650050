<template>
  <div id="industry-body">
    <div class="page-view">
      <h1 class="header-title mt-5">Industry Practices</h1>
      <div class="grid-2">
        <div class="pointers">
          <section
            class="block"
            v-for="(data, index) in industries"
            :key="data"
          >
            <span class="li-index">{{ index + 1 }}</span>
            <span class="li-data">{{ data }}</span>
          </section>
        </div>

        <img src="/industry-cropped.png" alt="Industry" class="img-hero" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { industries } from '@/assets/scripts/industry.js';
import Footer from '@/components/UI/Footer.vue';
export default {
  components: { Footer },
  data() {
    return {
      industries,
    };
  },
};
</script>

<style scoped>
.block {
  display: grid;
  grid-template-columns: 50px calc(100% - 50px);
  align-items: center;
  margin-bottom: 10px;
}
.li-index {
  background: var(--theme);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 5px;
}
.li-data {
  background: #f9f9f9;
  margin-left: 10px;
  padding: 5px 5px 5px 20px;
}
.img-hero {
  max-height: 600px;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  grid-gap: 0 2rem;
}
@media (max-width: 768px) {
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    grid-gap: 1rem 0m;
  }
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
