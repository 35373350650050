export const industries = [
  'Banking',
  'Insurance',
  'BPO & KPO',
  'Education',
  'Financial Services',
  'Consumer & Services',
  'Information Technology',
  'Media & Communications',
  'Life Sciences & Healthcare',
  'Media & Advertising',
  'Professional Services',
];
